@import "../base/variables";
@import "../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../tools/mixins";

#header-section {
  text-align: center;

  .container {
    max-width: 950px;
  }


  h2 {
    font-family: "Playfair Display";
    font-size: 48px;
    font-weight: normal;

    @include media-breakpoint-down(md) {
      font-size: 32px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 24px;
    }
  }

  .sep {
    margin: 40px 0;
    height: 2px;

    @include media-breakpoint-down(sm) {
      margin: 30px 0;
    }
  }

  .infos {
    margin-bottom: 25px;
    font-size: 18px;
    opacity: 0.5;

    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
      font-size: 16px;
    }

    .category, .author {
      font-weight: bold;
    }

    .category {
      text-decoration: underline;
    }
  }

  .big-image {
    height: 550px;
    width: 100%;
    object-fit: cover;

    @include media-breakpoint-down(md) {
      height: 450px;
    }

    @include media-breakpoint-down(sm) {
      height: 300px;
    }
  }
}

#introduction-section {
  margin-top: 90px;

  @include media-breakpoint-down(md) {
    margin-top: 70px;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 50px;
  }

  .container {
    max-width: 950px;
  }

  .left-text {
    font-family: "Playfair Display";
    font-size: 36px;
    line-height: 48px;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 36px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .right-text {
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;

    @include media-breakpoint-down(md) {
      margin-top: 15px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

#simple-text-section {
  margin-top: 50px;
  opacity: 0.83;
  font-size: 16px;
  line-height: 24px;

  iframe, video {
    max-width: 100%;
  }

  table {
    width: 100% !important;

    tr {
      td {
        padding: 5px;
        border: 1px solid $black;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    margin-top: 40px;
    font-size: 14px;
    line-height: 20px;
  }

  .container {
    max-width: 950px;
  }

}

#title-photo-caption-section {
  margin-top: 100px;

  @include media-breakpoint-down(md) {
    margin-top: 60px;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 40px;
  }

  .container {
    max-width: 950px;
  }


  .title {
    margin-bottom: 50px;
    font-family: "Playfair Display";
    font-size: 36px;
    line-height: 48px;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
      font-size: 24px;
      line-height: 36px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 25px;
      font-size: 18px;
      line-height: 24px;
    }
  }

  .main-image {
    width: 100%;
  }

  .caption {
    height: 55px;
    margin-top: -55px;
    padding: 0 20px;
    font-style: italic;
    font-size: 18px;
    line-height: 55px;
    background: rgba(18,18,18,0.68);
    color: rgba(255,255,255,0.83);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include media-breakpoint-down(sm) {
      height: 45px;
      margin-top: -45px;
      padding: 0 15px;
      font-size: 16px;
    }
  }
}

#text-button-blackblock-section {
  margin-top: 50px;

  .container {
    max-width: 950px;
  }

  .left-part {
    font-size: 16px;
    line-height: 24px;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
      line-height: 20px;
    }

    .button {
      margin-top: 35px;

      @include media-breakpoint-down(sm) {
        margin-top: 25px;
      }
    }
  }

  .blackblock {
    margin-left: 20px;
    padding: 50px 40px;
    background: #000;
    color: #fff;
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;

    @include media-breakpoint-down(md) {
      margin-top: 25px;
      margin-left: 0;
      padding: 40px 30px;
    }

    @include media-breakpoint-down(sm) {
      padding: 30px 20px;
      font-size: 16px;
      line-height: 22px;
    }

    .sep {
      display: block;
      height: 2px;
      margin-top: 25px;

      @include media-breakpoint-down(sm) {
        margin-top: 15px;
      }
    }
  }
}

#linked-documents-section {
  margin-top: 80px;

  @include media-breakpoint-down(md) {
    margin-top: 60px;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 40px;
  }

  .container {
    max-width: 950px;
  }

  #linked-documents-content {
    padding-top: 70px;
    border-top: 1px solid #a8a8a8;

    @include media-breakpoint-down(md) {
      padding-top: 50px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 40px;
    }

    .big-title {
      margin-bottom: 30px;
      font-family: "Playfair Display";
      font-size: 36px;
      font-weight: bold;

      @include media-breakpoint-down(md) {
        font-size: 24px;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
        font-size: 18px;
      }
    }

    #files {
      .file {
        @include media-breakpoint-down(md) {
          margin-bottom: 15px;
        }

        .icon {
          height: 34px;
          margin-right: 5px;

          @include media-breakpoint-down(sm) {
            height: 30px;
          }
        }

        .filename {
          font-weight: bold;
          opacity: 0.83;
          text-decoration: underline;

          @include media-breakpoint-down(sm) {
            display: block;
            padding-left: 40px;
            margin-top: -28px;
          }


          &:hover, &:active, &:focus {
            color: #000000;
            opacity: 1;
          }
        }
      }
    }
  }
}

#related-posts-section {
  margin-top: 200px;
  margin-bottom: 15px;
  text-align: center;

  @include media-breakpoint-down(md) {
    margin-top: 140px;
    text-align: left;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 80px;
  }

  > .button {
    margin-bottom: 15px;
    margin-left: 15px;

    &:first-child {
      @include media-breakpoint-down(sm) {
        img {
          display: none;
        }
      }
    }

    &.scroll-top {
      position: absolute;
      right: 0;
      padding: 10px 25px;
      margin-right: 15px;

      @include media-breakpoint-down(sm) {
        padding: 10px 15px;
      }

      img {
        height: 38px;

        @include media-breakpoint-down(sm) {
          height: 24px;
        }
      }
    }
  }

  .post {
    display: block;
    position: relative;
    height: 350px;
    background: #000;

    @include media-breakpoint-down(md) {
      height: 300px;
    }

    @include media-breakpoint-down(sm) {
      height: 200px;
      margin-bottom: 15px;
    }

    .post-image {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      object-fit: cover;
      opacity: 0.5;
      transition: opacity .5s ease-in-out;
    }

    .inner-content {
      padding: 50px 15%;
      text-align: center;
      color: #fff;

      @include media-breakpoint-down(md) {
        padding: 40px;
      }

      @include media-breakpoint-down(sm) {
        padding: 15px;
        top: 50%;
        transform: translateY(-50%);
      }

      .arrow {
        margin-bottom: 15px;
      }

      .label-post {
        color: #f0f0f0;
        font-size: 16px;
        line-height: 36px;
        font-weight: bold;
        text-transform: uppercase;

        @include media-breakpoint-down(sm) {
          font-size: 14px;
        }

        &:after {
          content: "";
          display: block;
          margin-top: 5px;
          background: rgba(255,255,255,0.25);
          height: 1px;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .post-title {
        margin-top: 20px;
        font-family: "Playfair Display";
        font-size: 24px;
        line-height: 36px;
        font-weight: bold;

        @include media-breakpoint-down(md) {
          font-size: 22px;
          line-height: 32px;
        }

        @include media-breakpoint-down(sm) {
          margin-top: 10px;
          font-size: 18px;
          line-height: 24px;
        }
      }
    }

    &:hover, &:focus, &:active {
      text-decoration: none;

      .post-image {
        opacity: 0.3;
      }
    }
  }
}